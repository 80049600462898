/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { string, array, func } from 'prop-types';
import selectStyles from './select.module.css';

const Select = ({
    label, id, dataTestId, name, dropDownList, handleChange,
}) => {
    useStyles(selectStyles);
    return (
        <div className={selectStyles.selectContainer} data-test={dataTestId}>
            <label htmlFor={id} className={selectStyles.selectLabel}>{label}</label>
            <select name={name} id={id} className={selectStyles.select} onChange={handleChange}>
                {dropDownList?.map((d, index) => {
                    if (typeof d === 'string') {
                        return (
                            <option
                                value={d}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`dropdown-${index}`}
                            >
                                {d}
                            </option>
                        );
                    }
                    return (
                        <option
                            value={d.option_name}
                            key={d.option_name}
                        >
                            {d.option_name}
                        </option>
                    );
                })}
            </select>
            <svg className={selectStyles.arrow} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z" /></svg>
        </div>
    );
};

Select.propTypes = {
    id: string.isRequired,
    dataTestId: string.isRequired,
    label: string.isRequired,
    name: string.isRequired,
    dropDownList: array.isRequired,
    handleChange: func,
};

Select.defaultProps = {
    handleChange: () => {},
};

export default Select;
