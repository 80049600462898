/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { string, func, bool } from 'prop-types';
import inputStyles from './zipinput.module.css';

const ZipInput = ({
    id, dataTest, label, name, type, defaultValue, value, handleChange, onSubmitCheck,
}) => {
    useStyles(inputStyles);
    // const inputProps = {
    //     defaultValue,
    //     value,
    //     handleFocus,
    //     handleKeyDown,
    //     ...(handleChange && { onChange: handleChange }),
    // }
    return (
        <>
            <div className={`${inputStyles.inputContainer} ${onSubmitCheck && inputStyles.inputErrorBorder}`}>
                <input
                    id={id}
                    data-test={dataTest}
                    name={name}
                    className={inputStyles.input}
                    type={type}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={handleChange}
                    required
                    pattern=".*\S.*"
                // {...inputProps}
                />
                {/* DO NOT MOVE PLACEMENT OF LABEL IT WILL BREAK CSS ANIMATION/PLACEMENT OF LABEL */}
                <label className={inputStyles.label} htmlFor={id}>{label}</label>
            </div>
        </>
    );
};

ZipInput.propTypes = {
    id: string.isRequired,
    dataTest: string.isRequired,
    label: string.isRequired,
    name: string.isRequired,
    type: string.isRequired,
    defaultValue: string,
    value: string,
    handleChange: func,
    onSubmitCheck: bool,
};

ZipInput.defaultProps = {
    defaultValue: '',
    value: '',
    handleChange: () => { },
    onSubmitCheck: false,
};

export default ZipInput;
