/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    object, func, string, array, bool, shape,
    number,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useMediaQuery } from '@material-ui/core';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GiftFinderProductContainer from '../../../GiftFinderProduct/GiftFinderProductContainer';
import GiftfinderDateField from './GiftfinderDateField';
import GiftFinderOccasionField from './GiftFinderOccasionField';
import convertAlphaDecToHex from '../../../../../helpers/contentstack/convertAlphaDecToHex';
import AddressZipField from './AddressZipField';
import ResponsiveWrapper from '../../../UniversalTemplate/SubComponents/ResponsiveWrapper';
import getBackgroundColor from '../../../../../helpers/getBackgroundColor';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 2,
        position: 'relative',
        margin: '0 15px 15px 15px',
        borderRadius: '5px',
        width: 'auto',
        minHeight: '200px',
        backgroundColor: 'transparent',
        overflow: 'visible',
        [theme.breakpoints.up(768)]: {
            margin: '0 auto', // check "data.margin" for default values
            width: '100%',
            minHeight: '104px',
            borderRadius: '0',
            border: 0,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            marginBottom: '18px',
            width: '980px',
        },
        '& $btnContainer button': {
            [theme.breakpoints.down(325)]: {
                width: '30% !important',
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
        [theme.breakpoints.down(768)]: {
            '& $giftFinderHorizontal': {
                margin: '0px 0px',
            },
        },
    },
    cardContentPadding: {
        padding: '24px 24px 24px 24px',
        [theme.breakpoints.up(768)]: {
            padding: '24px 10px',
        },
    },
    fagfContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up(768)]: {
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
    },
    sectionheaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: '0 0 auto',
        width: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down(768)]: {
            width: '100%',
            alignItems: 'stretch',
            marginRight: '0px',
            marginBottom: '12px',
        },
    },
    fagfSVG: {
        '& svg': {
            width: '24px',
            margin: '2px 5px 0 0',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                margin: '0',
                maxHeight: '24px',
            },
        },
        [theme.breakpoints.down(768)]: {
            display: 'flex',
            '& svg': {
                margin: '4px 5px 0 0',
            },
        },
    },
    sectionHeader: {
        fontSize: theme.palette?.text?.fontSize15 || '15px',
        marginBottom: 14,
        color: theme.palette.sectionHeaderTextColor,
        letterSpacing: '0.42px',
        fontWeight: '700',
        // tab desktop
        lineHeight: '1.43',
        [theme.breakpoints.up(768)]: {
            lineHeight: '32px',
            flex: '0 0 auto',
            fontSize: theme.typography?.fontSize || '16px',
            color: theme.palette.white,
            textTransform: 'unset',
            margin: '0 7px 0 5px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            margin: '0 16px 0 7px',
        },
        // mobile
        [theme.breakpoints.down(768)]: {
            width: 'auto',
            margin: 0,
            fontWeight: 'normal',
            fontSize: '28px',
            lineHeight: '30px',
            color: '#C81842',
            letterSpacing: '0px',
            fontFamily: 'PlayfairDisplayBold',
        },
        [theme.breakpoints.down(321)]: {
            fontSize: '26px',
        },
    },
    fagfInputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        [theme.breakpoints.up(768)]: {
            alignItems: 'flex-end',
            flexWrap: 'nowrap',
            width: 'auto',
        },
    },
    zipContainer: {
        flex: '100%',
        marginBottom: '10px',
        [theme.breakpoints.up(768)]: {
            flex: '1 0 110px',
            marginRight: '8px',
            minWidth: '130px',
            marginBottom: '0px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 0 160px',
            marginRight: '16px',
        },
        '& input': {
            opacity: '1 !important',
            '&:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
                borderRadius: '3px',
            },
        },
    },

    btnContainer: {
        margin: '0 auto',
        flex: '0 0 100%',
        marginTop: '10px',
        [theme.breakpoints.up(768)]: {
            flex: '0 1 160px',
            minWidth: '133px',
            marginTop: '0',
        },
    },
    button: {
        letterSpacing: '0.026rem',
        padding: '5px 9px',
        marginTop: '10px',
        color: '#000',
        fontWeight: 700,
        textTransform: 'unset',
        fontSize: theme.palette?.text?.fontSize14 || '14px',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
            borderRadius: '3px',
        },
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize15 || '15px',
        },
        [theme.breakpoints.down(768)]: {
            width: '100%',
            border: 'none',
            height: '50px',
            display: 'flex',
            padding: '0',
            fontSize: '18px',
            alignItems: 'center',
            fontFamily: 'LatoMedium',
            lineHeight: '18px',
            borderRadius: '3px',
            letterSpacing: '0.04em',
            justifyContent: 'center',
        },
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px 12px',
        },
    },
    imageContainer: {
        width: '24px',
        display: 'flex',
        height: '24px',
        margin: '0 5px 0 0',
        '& img': {
            width: '100%',
        },
    },
    negativeMargin: {
        '@media screen and (min-width: 768px)': {
            margin: '-3.3em auto 2em',
        },
        '@media screen and (max-width: 3200px) and (min-width: 1701px)': {
            marginTop: 'clamp(-2.6em, 1vw, -1.8em)',
        },
    },
    fitContainer: {
        width: '100%',
        maxWidth: '1024px',
    },
    wrapper: {
        width: 'max-content',
        '& $cardContentPadding': {
            padding: '32px',
        },
    },
    addressWrapper: {
        flex: '0 0 fit-content',
        '@media screen and (min-width:768px) and (max-width: 1024px)': {
            flex: '0 0 40%',
        },
        '@media screen and (max-width: 767px)': {
            flex: '0 0 100%',
        },
    },
    backgroundContainer: {
        backgroundSize: 'cover',
        height: '200px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '18px',
        '& $root': {
            marginBottom: '0px',
        },
        [theme.breakpoints.down(600)]: {
            height: '400px',
        },
    },
    prefetch: {
        display: 'none',
    },
}));

const GiftFinderFloralV2 = ({
    handleSubmit, data, giftFinderFieldStyle,
    handleChange, date, setSelectedOccasion,
    onSubmitCheck, occasion, dateOptions,
}) => {
    const classes = useStyles();
    const giftFinderSuggestionEnable = useSelector(getFeatureFlag('is-gift-finder-suggestions-enabled'));
    const headerData = data?.header || {};
    const isMobile = useMediaQuery('(max-width: 767px)');
    const productData = data?.product_recommendations || [];
    const stylingClr = data.styling?.colors || {};
    const backgroundImage = data.styling?.background_image?.url;
    const separateMobileColors = stylingClr?.has_separate_mobile_colors && isMobile;
    const mobileColors = stylingClr?.mobile_colors;
    const giftFinderBgColor = separateMobileColors ? getBackgroundColor(mobileColors?.background_color) : getBackgroundColor(stylingClr?.gift_finder_background_color) || '';
    const giftFieldSetColor = separateMobileColors ? getBackgroundColor(mobileColors?.fieldset_background_color) : getBackgroundColor(stylingClr?.fieldset_background_color) || '';
    const giftFinderBgColorOpacity = stylingClr?.background_color_opacity || 1;
    const inputLabelColor = separateMobileColors ? mobileColors?.text_color?.color : stylingClr?.text_color?.color;
    const negativeTopMargin = data.styling?.has_negative_top_margin;
    const styleWidthCls = data.styling?.dimensions?.width === 'fit_content' ? classes.fitContainer : classes.wrapper;
    const convertedGiftFinderBgColor = giftFinderBgColor ? convertAlphaDecToHex(giftFinderBgColor, giftFinderBgColorOpacity) : '';

    const defaultIcon = (
        <div className={classes.fagfSVG}>
            <svg
                viewBox="0 0 27 27"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                style={{
                    pointerEvents: 'none',
                    display: 'block',
                }}
            >
                <g>
                    <path
                        fill="#FFF"
                        style={{ fill: inputLabelColor }}
                        d="M22,12V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12A1,1 0 0,1 1,11V8A2,2 0 0,1 3,6H6.17C6.06,5.69 6,5.35 6,5A3,3 0 0,1 9,2C10,2 10.88,2.5 11.43,3.24V3.23L12,4L12.57,3.23V3.24C13.12,2.5 14,2 15,2A3,3 0 0,1 18,5C18,5.35 17.94,5.69 17.83,6H21A2,2 0 0,1 23,8V11A1,1 0 0,1 22,12M4,20H11V12H4V20M20,20V12H13V20H20M9,4A1,1 0 0,0 8,5A1,1 0 0,0 9,6A1,1 0 0,0 10,5A1,1 0 0,0 9,4M15,4A1,1 0 0,0 14,5A1,1 0 0,0 15,6A1,1 0 0,0 16,5A1,1 0 0,0 15,4M3,8V10H11V8H3M13,8V10H21V8H13Z"
                    />
                </g>
            </svg>
        </div>
    );

    // queryString delimeter for images from branches
    const imgObjString = JSON.stringify(data.styling?.background_image); // used elsewhere

    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    const imageSizes = {
        desktop: backgroundImage,
        tablet: backgroundImage, // can add fallbacks easily this way
        mobile: backgroundImage,
    };

    const fieldSetStyle = {};
    if (giftFieldSetColor) {
        fieldSetStyle.backgroundColor = giftFieldSetColor;
        fieldSetStyle.border = `2px solid ${stylingClr?.border_color?.color}`;
        fieldSetStyle.boxShadow = isMobile ? '' : '0 8px 4px -4px rgba(59,33,81,0.21)';
    }
    const imageQuality = '?auto=webp';

    const backgroundStyle = {};
    if (backgroundImage || convertedGiftFinderBgColor) {
        backgroundStyle.backgroundImage = backgroundImage ? `url(${backgroundImage}${imageQuality})` : '';
        backgroundStyle.backgroundColor = convertedGiftFinderBgColor;
    }
    const renderImagePrefetch = (matches) => <img src={`${imageSizes[device(matches)]}${imageQuality}`} width="0" height="0" alt="prefetch" className={classes.prefetch} />;
    return (
        <div style={backgroundStyle} className={backgroundImage || convertedGiftFinderBgColor ? classes.backgroundContainer : ''}>
            <div className={`${classes.root} ${styleWidthCls} ${negativeTopMargin ? classes.negativeMargin : ''} testCard`} style={fieldSetStyle} uniqueId="giftFinder">
                <div className={classes.cardContentPadding}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={classes.fagfContainer}>
                            <div className={classes.sectionheaderContainer}>
                                {headerData?.show_title_icon && (headerData?.image ? <span className={classes.imageContainer}><img src="" alt={data?.heading?.heading_copy}  /></span> : defaultIcon)}
                                {data?.heading?.heading_copy && (
                                    <div
                                        className={classes.sectionHeader}
                                        style={{ color: inputLabelColor }}
                                    >
                                        { data?.heading?.heading_copy}
                                    </div>
                                )}
                            </div>
                            <div className={classes.fagfInputContainer}>
                                {data?.gift_finder_blocks.map((block) => {
                                    const blockKey = Object.keys(block)?.[0];
                                    const blockObj = block[blockKey];
                                    switch (blockKey) {
                                        case 'delivery_address':
                                            return (
                                                <div className={`${classes.zipContainer} ${classes.addressWrapper}`}>
                                                    <AddressZipField
                                                        zipLabel={blockObj?.label || ''}
                                                        giftFinderBodyTextColor={inputLabelColor}
                                                        gfAutoFillAddressVariant
                                                        ziphandleChange={handleChange}
                                                        giftFinderFieldStyle={giftFinderFieldStyle}
                                                        giftFinderCodeTheoryView={isMobile}
                                                        onSubmitCheck={onSubmitCheck}
                                                        isLoadingOrVariableSkip={false}
                                                        zipOrAddressInputPlaceholder="Address or ZIP Code"
                                                    />
                                                </div>
                                            );
                                        case 'occasion':
                                            return (
                                                <div className={classes.zipContainer}>
                                                    <GiftFinderOccasionField
                                                        occasionTitle={blockObj?.title || ''}
                                                        occasionOption={blockObj?.occasion_option_group}
                                                        setSelectedOccasion={setSelectedOccasion}
                                                        occasion={occasion}
                                                        inputLabelColor={inputLabelColor}
                                                    />
                                                </div>
                                            );
                                        case 'delivery_date':
                                            return (
                                                <GiftfinderDateField
                                                    handleChange={handleChange}
                                                    dateOptions={dateOptions}
                                                    date={date}
                                                    deliveryDateTitle={blockObj?.label || ''}
                                                    inputLabelColor={inputLabelColor}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                                <div className={classes.btnContainer}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        id="fagf-btn"
                                        data-testid="fagf-btn"
                                        fullWidth
                                        className={classes.button}
                                        type="submit"
                                        style={{
                                            color: separateMobileColors ? mobileColors?.cta_color?.color : stylingClr?.cta_color?.color,
                                            background: separateMobileColors ? mobileColors?.cta_background_color?.color : stylingClr?.cta_background_color?.color,
                                        }}
                                    >
                                        <span
                                            id="find-a-gift-now-text"
                                            data-test="hp-find-a-gift-now-text"
                                            name="findAGiftNowText"
                                        >
                                            {data?.cta_copy || 'Find A Gift Now'}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {giftFinderSuggestionEnable && (
                    <div
                        className={classes.giftFinderHorizontal}
                        style={{
                            backgroundColor: productData?.styling?.module_background_color?.color,
                        }}
                    >
                        <GiftFinderProductContainer productData={productData} />
                    </div>
                )}
            </div>
            {imgObjString.includes('http') && <ResponsiveWrapper renderer={renderImagePrefetch} />}
        </div>
    );
};

GiftFinderFloralV2.propTypes = {
    data: object.isRequired,
    handleSubmit: func.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    setSelectedOccasion: func.isRequired,
    occasion: object.isRequired,
    dateOptions: array.isRequired,
    date: string.isRequired,
    giftFinderFieldStyle: object.isRequired,
};

export default GiftFinderFloralV2;
